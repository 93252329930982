import React, { useState } from 'react';
import {
  Container, Row, Col,
} from 'reactstrap';
import { Link } from 'gatsby';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import Layout from '../../components/layout';
import SEO from '../../components/seo';
import Section from '../../components/Section/Section';
import SimpleModernHeadline from '../../components/SimpleModernHeadline/SimpleModernHeadline';
import HeadlineWithImage from '../../components/HeadlineWithImage/HeadlineWithImage';

import img_leistung_8 from '../../images/leistungen/img8.jpg';

const LeistungenPage = () => (
  <Layout headerWhite>
    <SEO title="PRP Eigenbluttherapie | Dr. K. Kostka in Bochum" description="Dr. K. Kostka bietet PRP Eigenbluttherapien mitten in Bochum. Moderne Praxis ✓ Top Ausstattung ✓ Vereinbaren Sie jetzt einen Termin ✓"/>
    <Section>
      <Container>
        <Row className="justify-content-md-center">
          <Col md={12}>
            <HeadlineWithImage headline={"PRP Eigenbluttherapie"} subtitle={"Alterungsprozesse der Haut verlangsamen"} frontImage={img_leistung_8}></HeadlineWithImage>
          </Col>
        </Row>
        <p>
          Diese natürliche Verjüngungskur der Haut ist eine Unterspritzung mit körpereigenem, aufbereitetem Plasma in das betroffene Gebiet (Gesicht, Hals und Dekolleté). Hier führt es zu einer vermehrten Produktion von hautstraffendem Elastin, Kollagen und Hyaluron. Ganz ohne Operation können so auf schmerzarme Weise Alterungsprozesse der Haut verlangsamt und ein deutlich frischeres Aussehen erzielt werden. Es verschwinden die müden Augen und der fahle Gesichtsteint. Neue Spannkraft und straffere Haut werden sichtbar.
          <br/>
          <br/>
          Die Therapie wird 2-3 Mal im Abstand von 4 Wochen durchgeführt und hält ca. 1 Jahr.
          <br/>
          <br/>
          Sie können sich bei mir direkt in der Innenstadt von Bochum beraten und behandeln lassen.
          <br/>
          <br/>
          Gesicht: Vampirlifting/Faltenbehandlung mit Eigenblut
        </p>
      </Container>
    </Section>
  </Layout>
);

export default LeistungenPage;
